import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import Content from "components/front/corporate/atoms/Content";
import useTwitterWidget from "lib/hooks/front/useTwitterWidget";
import Headline from "components/front/corporate/atoms/Headline";
import { useDeviceType } from "lib/util";

export type Props = {};
const ActivitySection: FC<Props> = ({ ...props }) => {
  const [loaded, getTwitterWidget] = useTwitterWidget();
  const twitterTimelineRef = useRef<HTMLDivElement>(null);
  const { pc } = useDeviceType();
  useEffect(() => {
    if (loaded) {
      const twitterWidget = getTwitterWidget();
      const dataSource: Twitter.DataSource = { sourceType: "profile", screenName: "animefund" };
      const options = { width: "500px", height: pc ? "600px" : "400px" };
      twitterWidget?.createTimeline(dataSource, twitterTimelineRef.current, options);
    }
    return () => {
      if (twitterTimelineRef.current) twitterTimelineRef.current.innerHTML = "";
    };
  }, [loaded]);
  return (
    <section {...props}>
      <Headline>その他の活動</Headline>
      <Content>
        <Activity>
          <Title>Twitter</Title>
          <Description>活動状況を発信しています。</Description>
          <TwitterArea>
            <TwitterTimeline ref={twitterTimelineRef} />
          </TwitterArea>
        </Activity>
      </Content>
    </section>
  );
};
export default ActivitySection;

const Activity = styled.div`
  text-align: center;
  margin: 0 auto 30px;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
  @media screen and (min-width: 961px) {
    width: 500px;
  }
`;
const Title = styled.h3`
  font-size: 20px;
  margin-bottom: 10px;
`;
const Description = styled.p`
  line-height: 1.428em;
  margin: 5px 0;
`;
const TwitterArea = styled.div``;
const TwitterTimeline = styled.div``;
